import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { AuthState } from 'app/stores/all';
import { CookieService } from 'ngx-cookie-service';
import { UserModel } from 'app/models/user.model';
import { isNullOrUndefined } from 'app/helper-functions/helper.functions';
import { ActiveRoleEnum } from 'app/models/enums/roles.enum';
import { Store } from '@ngxs/store';

@Injectable()
export class AuthService {
    private _authenticated = true;

    /**
     * Constructor
     */
    constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _store: Store,
    private _cookiesService: CookieService) 
    {
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        const tokena = this._store.selectSnapshot(AuthState.token);

        localStorage.setItem('accessToken', tokena.toString());
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    setRefreshToken(token: string): Observable<string> {
        this.clearRefreshToken();
        const expires = new Date();
        // Set cookie to expire in 30 days (adjust as necessary)
        expires.setDate(expires.getDate() + 2);
      
        this._cookiesService.set('refreshToken', token, {
          expires,
          path: '/',
          secure: true, // Ensure the cookie is only sent over HTTPS
          sameSite: 'Strict', // Helps prevent CSRF attacks
          // Note: `HttpOnly` is a server-side setting and cannot be set directly via JavaScript.
          //       The server should ensure this attribute is set when sending the cookie.
        });
        return of(token);
      }

      getRefreshToken(): string | null {
        return this._cookiesService.get('refreshToken');
      }

      clearRefreshToken(): Observable<null> {
        this._cookiesService.delete('refreshToken', '/');
        return of(null);
      }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post('api/auth/sign-in', credentials).pipe(
            switchMap((response: any) => {
                // Store the access token in the local storage

                this.accessToken = response.accessToken;
                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient
            .post('api/auth/sign-in-with-token', {
                accessToken: this.accessToken,
            })
            .pipe(
                catchError(() =>
                    // Return false
                    of(false)
                ),
                switchMap((response: any) => {
                    // Replace the access token with the new one if it's available on
                    // the response object.
                    //
                    // This is an added optional step for better security. Once you sign
                    // in using the token, you should generate a new one on the server
                    // side and attach it to the response object. Then the following
                    // piece of code can replace the token with the refreshed one.
                    if (response.accessToken) {
                        this.accessToken = response.accessToken;
                    }

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    // Store the user on the user service
                    this._userService.user = response.user;

                    // Return true
                    return of(true);
                })
            );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage

        // Set the authenticated flag to false
        this._authenticated = false;
        this.clearRefreshToken();
        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: {
        name: string;
        email: string;
        password: string;
        company: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in

        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability

        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    isEducator(): boolean{
        const user = this._store.selectSnapshot(AuthState.details)
        if(isNullOrUndefined(user))
            return false;

        return user.ActiveRole.toLowerCase() == ActiveRoleEnum.Educator
    }

    isManager(): boolean{
        const user = this._store.selectSnapshot(AuthState.details)
        if(isNullOrUndefined(user))
            return false;

        return user.ActiveRole.toLowerCase() == ActiveRoleEnum.PreschoolInstitutionManager
    }

    isParent(): boolean{
        const user = this._store.selectSnapshot(AuthState.details)
        if(isNullOrUndefined(user))
            return false;

        return user.ActiveRole.toLowerCase() == ActiveRoleEnum.Parent
    }

    isPAdmin(): boolean{
        const user = this._store.selectSnapshot(AuthState.details)
        if(isNullOrUndefined(user))
            return false;

        return user.ActiveRole.toLowerCase() == ActiveRoleEnum.PreschoolInstitutionAdministrator
    }
}
