<div class="-m-5">
    <div class="-pt-2 flex items-center">
        <div class="flex flex-col ml-5" *ngIf="isEducator">
            <div class="flex items-center">
                <mat-icon class="icon-size-5"
                          [svgIcon]="'heroicons_solid:user'"
                          matPrefix>
                </mat-icon>
                <span class="ml-2 text-lg font-bold">{{ payment.child }}</span>
                <div class="mx-4 h-6 border-l-2 border-gray-300"></div>

                <mat-icon class="icon-size-5"
                          [svgIcon]="'heroicons_solid:calendar'"
                          matPrefix>
                </mat-icon>
                <span class="ml-2 text-lg font-bold">{{ payment.month }}/{{ payment.year }}</span>
            </div>
        </div>
        <button mat-icon-button class="mb-2 mt-2 mr-1 ml-auto" (click)="closeDialog()" color="light">
            <mat-icon class="">close</mat-icon>
        </button>
    </div>

    <div class="mt-3 flex items-center" *ngIf="isEducator">
        <div class="flex-grow border-b-2 mx-4"></div>
    </div>

    <div class="slider">
        <div>
            <div (click)="goToPrevious()" *ngIf="shouldShowArrows" class="leftArrow">❰</div>
            <div (click)="goToNext()" *ngIf="shouldShowArrows" class="rightArrow">❱</div>
        </div>
        <img class="object-cover rounded-2xl" [(src)]="currentPicture" />
    </div>
</div>
