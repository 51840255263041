import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { ParentGuard, RoleGuard } from './guards/all';
import { E401Component } from './modules/errors/e401/e401.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    /* Public module for preschool institutions */
    {
       path: 'vrtic',
       component: LayoutComponent,
       data : {
        layout: 'empty'
       },
       children: [
        {
            path: '',
            redirectTo: 'clients',
            pathMatch: 'full'
        },
        {
            path: '',
            loadChildren: () => import('app/modules/public/public.module').then((m) => m.PublicModule)
        }
       ]
    },

    // Auth routes for guests
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirm-email-update',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirm-email-update/confirm-email-update.module'
                    ).then((m) => m.ConfirmEmailUpdateModule),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.module'
                    ).then((m) => m.AuthForgotPasswordModule),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.module'
                    ).then((m) => m.AuthResetPasswordModule),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        (m) => m.AuthSignInModule
                    ),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.module').then(
                        (m) => m.AuthSignUpModule
                    ),
            },
            {
                path: 'chooseroles',
                loadChildren: () =>
                    import(
                        'app/modules/auth/roles/choose-roles/choose-roles.module'
                    ).then((m) => m.ChooseRolesModule),
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/home',
            },     
        ],
    },

    // Settings
    {
        path: '',
        canActivate: [AuthGuard],
        component: LayoutComponent,

        resolve: {
            initialData: InitialDataResolver,
        },
        data: {},
        children: [
            {
                path: 'user-settings',
                loadChildren: () =>
                    import('app/modules/settings/settings.module').then(
                        (m) => m.SettingsModule
                    ),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        /*         canActivate: [RoleGuard],
         */ component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        (m) => m.AuthSignOutModule
                    ),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.module'
                    ).then((m) => m.AuthUnlockSessionModule),
            },
        ],
    },
    /*  errors resolver */
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'error',
                loadChildren: () =>
                    import('app/modules/errors/errors.module').then(
                        (m) => m.ErrorsModule
                    ),
            },
        ],
    },

    /*  chat */

    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard, ParentGuard],
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'chat',
                loadChildren: () =>
                    import('app/modules/chat/chat.module').then(
                        (m) => m.ChatModule
                    ),
                data: {},
            },
        ],
    },

    /*  home page / landing */

    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard, ParentGuard],
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/landing/home/home.module').then(
                        (m) => m.LandingHomeModule
                    ),
            },
        ],
    },
    /*  dashboard */
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard, RoleGuard],
        resolve: {
            initialData: InitialDataResolver,
        },
        data: {
            expectedRoles: [
                '"SuperAdministrator"',
                '"PreschoolInstitutionAdministrator"',
                '"PreschoolInstitutionManager"',
            ],
        },

        children: [
            {
                path: 'dashboard',
                loadChildren: () =>
                    import(
                        'app/modules/shared/dashboard/dashboard.module'
                    ).then((m) => m.DashboardModule),
            },
        ],
    },

    /*  parent  */

    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard, RoleGuard, ParentGuard],
        data: {
            layout: 'centered',
            expectedRoles: ['"Parent"'],
        },
        children: [
            {
                path: 'children',
                loadChildren: () =>
                    import('app/modules/parent/children/children.module').then(
                        (m) => m.ChildrenModule
                    ),
            },
            {
                path: 'calendar',
                loadChildren: () =>
                    import('app/modules/parent/calendar/parent-calendar.module').then(
                        (m) => m.ParentCalendarModule
                    ),
            },
            {
                path: 'waiting-list',
                loadChildren: () =>
                    import('app/modules/parent/waiting-list/list.module').then(
                        (m) => m.ListModule
                    ),
            },
        ],
    },

    /*   redirect if app is not finished     */

    /*     {
        path: '',
        component: LayoutComponent,
        canActivate: [],
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'coming-soon',
                loadChildren: () =>
                    import('app/modules/coming-soon/coming-soon.module').then(
                        (m) => m.ComingSoonModule
                    ),
            },
        ],
    },
 */
    /*   redirect if parent did not sign children     */
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'newenrollment',
                loadChildren: () =>
                    import(
                        'app/modules/parent/new-enrollment/new-enrollment.module'
                    ).then((m) => m.NewEnrollmentModule),
            },
        ],
    },


    /*  institution manager  */

    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard, RoleGuard],
        resolve: {
            initialData: InitialDataResolver,
        },

        data: {
            expectedRoles: ['"PreschoolInstitutionManager"'],
        },
        children: [
            {
                path: 'institutiondocuments',
                loadChildren: () =>
                    import(
                        'app/modules/institution-manager/documents/documents.module'
                    ).then((m) => m.DocumentsModule),
            },
            {
                path: 'enrollmentslist',
                loadChildren: () =>
                    import(
                        'app/modules/institution-manager/enrollments/enrollments.module'
                    ).then((m) => m.EnrollmentsModule),
            },
            {
                path: 'monitoring&adjustmentlist',
                loadChildren: () =>
                    import(
                        'app/modules/institution-manager/monitoring/monitoring.module'
                    ).then((m) => m.MonitoringModule),
            },

            {
                path: 'placeholderrequests',
                loadChildren: () =>
                    import(
                        'app/modules/institution-manager/placeholders/placeholders.module'
                    ).then((m) => m.PlaceholdersModule),
            },
            {
                path: 'manager-payments',
                loadChildren: () =>
                    import(
                        'app/modules/institution-manager/payments/manager-payments.module'
                    ).then((m) => m.ManagerPaymentsModule),
            },
            {
                path: 'attendances-stats',
                loadChildren: () =>
                    import(
                        'app/modules/shared/attendances-stats/enrollment-attendances-stats.module'
                    ).then((m) => m.EnrollmentAttendancesStatsModule),
            },
            {
                path: 'preschoolgroup-employees',
                loadChildren: () =>
                    import(
                        'app/modules/institution-manager/groups/preschoolgroup-employees.module'
                    ).then((m) => m.PreschoolGroupEmployeesModule),
            },
        ],
    },
    /*  educator  */
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard, RoleGuard],
        resolve: {
            initialData: InitialDataResolver,
        },

        data: {
            expectedRoles: ['"Educator"'],
        },
        children: [
            {
                path: 'educator-announcements',
                loadChildren: () =>
                    import(
                        'app/modules/educator/announcements/educator-announcements.module'
                    ).then((m) => m.EducatorAnnouncementsModule),
            },
            {
                path: 'educator-attendances',
                loadChildren: () =>
                    import(
                        'app/modules/educator/attendances/educator-attendances.module'
                    ).then((m) => m.EducatorAttendancesModule),
            },
            {
                path: 'educator-attendances-stats',
                loadChildren: () =>
                    import(
                        'app/modules/shared/attendances-stats/enrollment-attendances-stats.module'
                    ).then((m) => m.EnrollmentAttendancesStatsModule),
            },
            {
                path: 'children&records',
                loadChildren: () =>
                    import(
                        'app/modules/educator/contacts/contacts.module'
                    ).then((m) => m.ContactsModule),
            },
            {
                path: 'monitoring&adjustment',
                loadChildren: () =>
                    import(
                        'app/modules/educator/monitoring/monitoring.module'
                    ).then((m) => m.MonitoringModule),
            },
            {
                path: 'weeklyActivities',
                loadChildren: () =>
                    import(
                        'app/modules/educator/weekly-activities/weekly-activities.module'
                    ).then((m) => m.WeeklyActivitiesModule),
            },
            {
                path: 'child',
                loadChildren: () =>
                    import('app/modules/parent/children/children.module').then(
                        (m) => m.ChildrenModule
                    ),
            },
            {
                path: 'educator-payments',
                loadChildren: () =>
                    import('app/modules/educator/payments/educator-payments.module').then(
                        (m) => m.EducatorPaymentsModule
                    ),
            },
            {
                path: 'placeholders',
                loadChildren: () =>
                    import(
                        'app/modules/educator/placeholders/placeholders.module'
                    ).then((m) => m.PlaceholdersEducatorModule),
            },
            {
                path: 'menu',
                loadChildren: () =>
                    import('app/modules/educator/menu/educator-menu.module').then(
                        (m) => m.EducatorMenuModule
                    ),
            },
        ],
    },
    /*  institution admin  */
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard, RoleGuard],
        resolve: {
            initialData: InitialDataResolver,
        },

        data: {
            expectedRoles: ['"PreschoolInstitutionAdministrator"'],
        },
        children: [
            {
                path: 'document-types',
                loadChildren: () =>
                    import(
                        'app/modules/institution-administrator/document-types/document-types.module'
                    ).then((m) => m.InstitutionDocumentTypesModule),
            },
            {
                path: 'institution-config',
                loadChildren: () =>
                    import(
                        'app/modules/institution-administrator/config/config/config.module'
                    ).then((m) => m.ConfigModule),
            },

            {
                path: 'business-units',
                loadChildren: () =>
                    import(
                        'app/modules/institution-administrator/business-units/business-units.module'
                    ).then((m) => m.BusinessUnitsModule),
            },
            
            {
                path: 'programs',
                loadChildren: () =>
                    import(
                        'app/modules/institution-administrator/programs/programs.module'
                    ).then((m) => m.InstitutionProgramsModule),
            },
            {
                path: '',
                loadChildren: () =>
                    import('app/modules/shared/modals/modals.module').then(
                        (m) => m.ModalsModule
                    ),
            },
            {
                path: 'groups',
                loadChildren: () =>
                    import(
                        'app/modules/institution-administrator/groups/groups.module'
                    ).then((m) => m.InstitutionGroupsModule),
            },

            {
                path: 'evaluation-items',
                loadChildren: () =>
                    import(
                        'app/modules/institution-administrator/evaluation-items/evaluation-items.module'
                    ).then((m) => m.EvaluationItemsModule),
            },
            {
                path: 'settings',
                loadChildren: () =>
                    import(
                        'app/modules/institution-administrator/settings/settings.module'
                    ).then((m) => m.SettingsModule),
            },
            {
                path: 'activities',
                loadChildren: () =>
                    import(
                        'app/modules/institution-administrator/activities/activities.module'
                    ).then((m) => m.InstitutionActivitiesModule),
            },
            {
                path: 'meal-types',
                loadChildren: () =>
                    import(
                        'app/modules/institution-administrator/meal-types/meal-types.module'
                    ).then((m) => m.MealTypesModule),
            },
        ],
    },

    /*  super admin  */

    {
        path: '',
        canActivate: [AuthGuard, RoleGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        data: {
            expectedRoles: ['"SuperAdministrator"'],
        },
        children: [
            {
                path: 'cities',
                loadChildren: () =>
                    import('app/modules/admin/cities/cities.module').then(
                        (m) => m.CitiesModule
                    ),
            },
            {
                path: 'countries',
                loadChildren: () =>
                    import('app/modules/admin/countries/countries.module').then(
                        (m) => m.CountriesModule
                    ),
            },
            {
                path: 'eventtypes',
                loadChildren: () =>
                    import(
                        'app/modules/admin/event-types/event-types.module'
                    ).then((m) => m.EventTypesModule),
            },
            {
                path: 'institutions',
                children: [
                    {
                        path: 'preschools',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/preschool-institutions/preschool-institutions.module'
                            ).then((m) => m.PreschoolInstitutionsModule),
                    },
                    {
                        path: 'businessunits',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/business-units/business-units.module'
                            ).then((m) => m.BusinessUnitsModule),
                    },
                ],
            },
            {
                path: 'school-years',
                loadChildren: () =>
                    import('app/modules/admin/school-years/school-years.module').then(
                        (m) => m.SchoolYearsModule
                    ),
            },

            {
                path: 'documents',
                loadChildren: () =>
                    import('app/modules/admin/documents/documents.module').then(
                        (m) => m.DocumentsModule
                    ),
            },
            {
                path: 'programs',
                children: [
                    {
                        path: 'primaryprograms',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/primary-programs/primary-programs.module'
                            ).then((m) => m.PrimaryProgramsModule),
                    },
                    {
                        path: 'developmentalprograms',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/developmental-programs/developmental-programs.module'
                            ).then((m) => m.DevelopmentalProgramsModule),
                    },
                ],
            },
            {
                path: 'evaluation',
                loadChildren: () =>
                    import(
                        'app/modules/admin/evaluation/evaluation.module'
                    ).then((m) => m.EvaluationModule),
            },
            {
                path: 'activity-types',
                loadChildren: () =>
                    import(
                        'app/modules/admin/activity-types/activity-types.module'
                    ).then((m) => m.ActivityTypesModule),
            },
            {
                path: 'all-meal-types',
                loadChildren: () =>
                    import(
                        'app/modules/admin/meal-types/admin-meal-types.module'
                    ).then((m) => m.AdminMealTypesModule),
            }
        ],
    },

    //preschool institution administrator and preschool institution manager
    {
        path: '',
        canActivate: [AuthGuard, RoleGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        data: {
            expectedRoles: [
                '"PreschoolInstitutionAdministrator"',
                '"PreschoolInstitutionManager"',
            ],
        },
        children: [
            {
                path: 'events',
                loadChildren: () =>
                    import('app/modules/shared/events/events.module').then(
                        (m) => m.EventsModule
                    ),
            },
            {
                path: 'announcements',
                loadChildren: () =>
                    import(
                        'app/modules/shared/announcements/announcements.module'
                    ).then((m) => m.AnnouncementsModule),
            },
            {
                path: 'announcements-calls',
                loadChildren: () =>
                    import(
                        'app/modules/shared/announcements/announcements.module'
                    ).then((m) => m.AnnouncementsModule),
            },
            {
                path: 'announcements-tenders',
                loadChildren: () =>
                    import(
                        'app/modules/shared/announcements/announcements.module'
                    ).then((m) => m.AnnouncementsModule),
            },
            // {
            //     path: 'gallery',
            //     loadChildren: () =>
            //         import(
            //             'app/modules/shared/gallery/gallery.module'
            //         ).then((m) => m.GalleryModule),
            // },
            {
                path: 'staff-management',
                loadChildren: () =>
                    import(
                        'app/modules/shared/staff-management/staff-management.module'
                    ).then((m) => m.StaffManagementModule),
            },
            {
                path: 'children-management',
                loadChildren: () =>
                    import(
                        'app/modules/shared/children-management/children.module'
                    ).then((m) => m.ChildrenManagementModule),
            },
            {
                path: 'parents-management',
                loadChildren: () =>
                    import(
                        'app/modules/shared/parents-management/parents-management.module'
                    ).then((m) => m.ParentsManagementModule),
            },
        ],
    },
    {
        path: '',
        canActivate: [AuthGuard, RoleGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        data: {
            expectedRoles: [
                '"PreschoolInstitutionAdministrator"',
                '"PreschoolInstitutionManager"',
                '"Educator"'
            ],
        },
        children: [
            {
                path: 'gallery',
                loadChildren: () =>
                    import(
                        'app/modules/shared/gallery/gallery.module'
                    ).then((m) => m.GalleryModule),
            }
        ],
    },
    {
        path: '401-unauthorized',
        pathMatch: 'full',
        component: E401Component,
    },
    {
        path: '404-not-found',
        pathMatch: 'full',
        loadChildren: () =>
            import('app/modules/errors/errors.module').then(
                (m) => m.ErrorsModule
            ),
    },
    { path: '**', redirectTo: '404-not-found' },
];
