import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { EmployeeModel } from '../models/all';
import { ApiService, StorageService } from './all';

@Injectable({ providedIn: 'root' })
export class EmployeesService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    getPublic(preschoolInstitutionId: number) {
        return this.sendGetRequest<EmployeeModel[]>(
            'employees/getpublicinformation?preschoolInstitutionId=' + preschoolInstitutionId,
        );
    }

    isCoordinator(){
        return this.sendGetRequest<boolean>('employees/iscoordinator');
    }

}
