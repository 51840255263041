import { BaseRequest } from './base.request';

export class PlaceholderGetRequest implements BaseRequest {
    constructor(
        public institutionId: number,
        public applicantId: number | null,
        public childId: number | null,
        public name: string | null,
        public businessUnitId: number | null,
        public from: any | null,
        public to: any | null,
        public statusType: number | null,
        public paid: boolean | null,
        public groupId: number | null,
        public schoolYearId: number | null,
        public page: number | null,
        public pageSize: number | null
    ) {}
    toParams(): Object {
        return {
            InstitutionId: this.institutionId,
            ...(this.applicantId && { ApplicantId: this.applicantId }),
            ...(this.childId && { ChildId: this.childId }),
            ...(this.name && { Name: this.name }),
            ...(this.businessUnitId && { BusinessUnitId: this.businessUnitId }),
            ...(this.from && { From: this.from }),
            ...(this.to && { To: this.to }),
            ...(this.statusType && { StatusType: this.statusType }),
            ...(this.groupId && { GroupId: this.groupId }),
            ...(this.paid && { Paid: this.paid }),
            ...(this.schoolYearId && { SchoolYearId: this.schoolYearId }),
            ...(this.page && { Page: this.page }),
            ...(this.pageSize && { PageSize: this.pageSize }),
        };
    }
    toBody(): Object {
        return {
            InstitutionId: this.institutionId,
            ...(this.applicantId && { ApplicantId: this.applicantId }),
            ...(this.childId && { ChildId: this.childId }),
            ...(this.name && { Name: this.name }),
            ...(this.businessUnitId && { BusinessUnitId: this.businessUnitId }),
            ...(this.from && { From: this.from }),
            ...(this.to && { To: this.to }),
            ...(this.statusType && { StatusType: this.statusType }),
            ...(this.groupId && { GroupId: this.groupId }),
            ...(this.paid && { Paid: this.paid }),
            ...(this.schoolYearId && { SchoolYearId: this.schoolYearId }),
            ...(this.page && { Page: this.page }),
            ...(this.pageSize && { PageSize: this.pageSize }),
        };
    }
}

export class PlaceholderGetForEducatorRequest implements BaseRequest {
    constructor(
        public institutionId: number,
        public name: string | null,
        public from: any | null,
        public to: any | null,
        public statusType: number | null,
        public groupId: number[] | null,
        public paid: boolean | null,
        public page: number | null,
        public pageSize: number | null
    ) {}
    toParams(): Object {
        return {
            InstitutionId: this.institutionId,
            ...(this.name && { Name: this.name }),
            ...(this.from && { From: this.from }),
            ...(this.to && { To: this.to }),
            ...(this.statusType && { StatusType: this.statusType }),
            ...(this.groupId && { GroupId: this.groupId }),
            ...(this.paid && { Paid: this.paid }),
            ...(this.page && { Page: this.page }),
            ...(this.pageSize && { PageSize: this.pageSize }),
        };
    }
    toBody(): Object {
        return {
            InstitutionId: this.institutionId,
            ...(this.name && { Name: this.name }),
            ...(this.from && { From: this.from }),
            ...(this.to && { To: this.to }),
            ...(this.statusType && { StatusType: this.statusType }),
            ...(this.groupId && { GroupId: this.groupId }),
            ...(this.paid && { Paid: this.paid }),
            ...(this.page && { Page: this.page }),
            ...(this.pageSize && { PageSize: this.pageSize }),
        };
    }
}

export class PlaceholderInsertRequest implements BaseRequest {
    constructor(
        public id: number | null,
        public enrollmentId: number,
        public applicantId: number,
        public from: any,
        public to: any,
        public amount: number,
        public statusType: number,
        public paid: boolean,
        public paymentSlipId: number | null,
        public verified: boolean
    ) {}
    toParams(): Object {
        return {
            ...(this.id && { id: this.id }),
            enrollmentId: this.enrollmentId,
            applicantId: this.applicantId,
            from: this.from,
            to: this.to,
            amount: this.amount,
            statusType: this.statusType,
            paid: this.paid,
            ...(this.paymentSlipId && { paymentSlipId: this.paymentSlipId }),
            verified: this.verified,
        };
    }
    toBody(): Object {
        return {
            ...(this.id && { id: this.id }),
            enrollmentId: this.enrollmentId,
            applicantId: this.applicantId,
            from: this.from,
            to: this.to,
            amount: this.amount,
            statusType: this.statusType,
            paid: this.paid,
            ...(this.paymentSlipId && { paymentSlipId: this.paymentSlipId }),
            verified: this.verified,
        };
    }
}

export class PlaceholderUpdateRequest implements BaseRequest {
    constructor(
        public id: number,
        public enrollmentId: number,
        public applicantId: number,
        public from: any,
        public to: any,
        public amount: number,
        public statusType: number,
        public paid: boolean,
        public paymentSlipId: number,
        public isActive: boolean,
        public childReturned: number
    ) {}
    toParams(): Object {
        return {
            id: this.id,
            enrollmentId: this.enrollmentId,
            applicantId: this.applicantId,
            from: this.from,
            to: this.to,
            amount: this.amount,
            statusType: this.statusType,
            paid: this.paid,
            ...(this.paymentSlipId && { paymentSlipId: this.paymentSlipId }),
            isActive: this.isActive,
            childReturned: this.childReturned,
        };
    }
    toBody(): Object {
        return {
            id: this.id,
            enrollmentId: this.enrollmentId,
            applicantId: this.applicantId,
            from: this.from,
            to: this.to,
            amount: this.amount,
            statusType: this.statusType,
            paid: this.paid,
            paymentSlipId: this.paymentSlipId,
            isActive: this.isActive,
            childReturned: this.childReturned,
        };
    }
}

export class PlaceholderDeleteRequest implements BaseRequest {
    constructor(public id: number) {}
    toParams(): Object {
        return {
            id: this.id,
        };
    }
    toBody(): Object {
        return {
            id: this.id,
        };
    }
}

export class PlaceholderStatusHistoryRequest implements BaseRequest {
    constructor(public id: number) {}
    toParams(): Object {
        return {
            Id: this.id,
        };
    }
    toBody(): Object {
        return {
            Id: this.id,
        };
    }
}

export class PlaceholderGetNotificationForChildRequest implements BaseRequest {
    constructor(
        public preschoolInstitutionid: number,
        public preschoolGroupId: number[] | null
    ) {}
    toParams(): Object {
        return {
            PreschoolInstitutionId: this.preschoolInstitutionid,
            GroupId: this.preschoolGroupId,
        };
    }
    toBody(): Object {
        return {
            PreschoolInstitutionId: this.preschoolInstitutionid,
            GroupId: this.preschoolGroupId,
        };
    }
}
