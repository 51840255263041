import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class DateTimeConvertService {
    convertToString(date): string {
        if (date != null) {
            return `${
                date?.getMonth() + 1
            }/${date?.getDate()}/${date?.getFullYear()}`;
        }

        //return this.parseToISO(date);
    }

    convertDateToString(date): string {
        if (date != null) {
            return `${date?.getDate()}.${
                date?.getMonth() + 1
            }.${date?.getFullYear()}`;
        }
    }

    convertDateToFullDateTimeString(date): string {
        if (date != null) {
            return `${date?.getDate()}.${
                date?.getMonth() + 1
            }.${date?.getFullYear()} ${date?.getHours()}:${date?.getMinutes()}`;
        }
    }

    parseToISO(dateString) {
        if (dateString == null) {
            return null;
        }

        if (moment.isMoment(dateString)) {
            return dateString.toISOString();
        }

        // Split the date and time parts
        const [datePart, timePart] = dateString.split(', ');

        // Parse the date part
        const [month, day, year] = datePart.split('/').map(Number);

        // Parse the time part
        const [time, period] = timePart.split(' ');
        let [hours, minutes, seconds] = time.split(':').map(Number);

        // Adjust hours for AM/PM
        if (period === 'PM' && hours !== 12) {
            hours += 12;
        } else if (period === 'AM' && hours === 12) {
            hours = 0;
        }

        // Convert to ISO string components
        const isoYear = String(year).padStart(4, '0');
        const isoMonth = String(month).padStart(2, '0');
        const isoDay = String(day).padStart(2, '0');
        const isoHours = String(hours).padStart(2, '0');
        const isoMinutes = String(minutes).padStart(2, '0');
        const isoSeconds = String(seconds).padStart(2, '0');

        // Construct ISO string (without milliseconds and time zone)
        const isoString = `${isoYear}-${isoMonth}-${isoDay}T${isoHours}:${isoMinutes}:${isoSeconds}.000Z`;

        return isoString;
    }

    convertDateToStringApiFormat(date: any): string {
        let dateObject: Date;

        if (moment.isMoment(date)) {
            dateObject = date.toDate();
        } else if (date instanceof Date) {
            dateObject = date;
        } else {
            return null;
        }

        if (dateObject && !isNaN(dateObject.getTime())) {
            const month = dateObject.getMonth() + 1;
            const day = dateObject.getDate();
            const year = dateObject.getFullYear();
            return `${month.toString().padStart(2, '0')}/${day
                .toString()
                .padStart(2, '0')}/${year}`;
        }

        return null;
    }
}
