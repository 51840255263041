import { subYears } from "date-fns";

export const institutionId = 'institutionId';
export const activeRole = 'activeRole';
export const minBirthDateInput: Date = subYears(new Date(), 100);
export const minBirthDateInputForChild: Date = subYears(new Date(), 5);
export const maxBirthDateInput: Date = new Date();
export const minDateFromPreschoolGroupEmployee: Date = new Date();
export const minDateToPreschoolGroupEmployee: Date = (() => {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    return today;
})();
export const maleChildPhotoUrl: string = 'assets/images/avatars/child-avatar-male.png';
export const femaleChildPhotoUrl: string = 'assets/images/avatars/child-avatar-female.png';
export const noUnitImageUrl: string = 'assets/images/ui/no-unit-image.png';
export const requestAnnouncementUrl: string = '/assets/img/annoucements/request.png';
export const loudSpeakerUrl: string = 'assets/img/annoucements/loudspeaker.png';
export const tenderUrl: string = '/assets/img/annoucements/tender.png';
export const stickyNoteUrl: string = '/assets/images/ui/sticky-note.png';
export const noPersonAvailableUrl: string = 'assets/images/ui/NoPersonAvailable.png'
export const errorLoadingPictureUrl: string = '/assets/img/file-formats/doc.png';
export const logoTextUrl: string = 'assets/images/logo/logo.svg';
export const logoPublicPageUrl: string = 'assets/images/logo/logo-public-page.png';
export const allowedDocumentExtensions: string = '.txt, .pdf';
export const defaultDebounceTime: number = 300;